import {getEndpointUrl} from "./utils.service";


const CategoriaService = {
     getAllCategorias(){

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return new Promise((resolve, reject) => {

            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/categorias/all', requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        })

    }

}

export default CategoriaService;
