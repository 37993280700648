import {getEndpointUrl} from "./utils.service";


export const ProdutoContent = {

    async getDestaquesHome(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/conteudo/produtos/destaque-home', requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
}


