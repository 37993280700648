import * as React from "react"
import { navigate} from "gatsby"

import * as ConteudoService from '../services/conteudo.service'

import Layout from "../components/layout"
import Seo from "../components/seo"
import {ShimmerThumbnail} from "react-shimmer-effects";
import CategoriaService from "../services/categorias.service";
import CarrinhoService from "../services/carrinho.service";
import ProdutoEstoqueUiComponent from "../components/ui/produtoEstoque.ui.component";
import PesquisarInputUiComponent from "../components/ui/pesquisarInput.ui.component";

class IndexPage extends React.Component {

    state = {
        loading:true,
        destaquesHomeList:[],
        categoriasList:[],
        pequisarTermo:''
    }
    constructor(props) {
        super(props);

        this.loadDestaqueHome = this.loadDestaqueHome.bind(this)
        this.loadCategorias = this.loadCategorias.bind(this)
        this.addItemCarrinho = this.addItemCarrinho.bind(this)
    }


    componentDidMount() {
        this.loadDestaqueHome()
        this.loadCategorias();
    }

    loadDestaqueHome(){
        this.setState({loading:true})

        ConteudoService.ProdutoContent.getDestaquesHome().then(produtosEstoqueList=>{

            this.state.destaquesHomeList = produtosEstoqueList
            this.setState({
                loading:false,
                destaquesHomeList:produtosEstoqueList
            })
        }).catch(err=>{

            console.error(err)
        }).finally(()=> this.setState({loading:false}))

    }

    loadCategorias(){

        CategoriaService.getAllCategorias().then(categoriasList=>{
            this.setState({
                categoriasList:categoriasList
            })
        }).catch(err=>{

        })

    }


    addItemCarrinho(produtoEstoque){

        let ref =this
        CarrinhoService.adicionarItemCarrinho(produtoEstoque,1).then(()=>{
            window.toastr.success('Item adicionado com sucesso','',{closeButton : true})
            ref.setState({refresh:true})
        })

    }

   render(){
        return (
            <Layout>
                <Seo title="Orthonet shop!" />

                <div className="container">
                        <div className="row">
                            <div className="w-100">
                                <PesquisarInputUiComponent />

                                <div className='mt-3 container' style={{marginTop:'25px'}} >
                                    <div className="row">
                                      {/*  <strong>Categorias</strong>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>
                                        <a href="" style={{fontSize:'20px'}}><span className="label label-primary">Primary</span></a>*/}

                                        <nav className="navbar navbar-default hide">
                                            <div className="container-fluid">
                                                <div className="navbar-header">

                                                    <a className="navbar-brand" href="#">Categorias</a>
                                                </div>

                                                <div className="collapse navbar-collapse"
                                                     id="bs-example-navbar-collapse-1">
                                                    <ul className="nav navbar-nav">
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>
                                                        <li><a href="#">Link</a></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>


                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
             {/*   <div className="basic-breadcrumb-area bg-opacity bg-1 ptb-100">
                    <div className="container">
                        <div className="basic-breadcrumb text-center">
                            <h3 class="">Produtos</h3>
                  <ol class="breadcrumb text-xs">
                      <li><a href="index.html">Home</a></li>
                      <li><a href="#">Product</a></li>
                      <li class="active">Product</li>
                  </ol>
                        </div>
                    </div>
                </div>*/}

                <div className="product-area pt-10 pb-60">
                    <div className="container">
                        <div className="row mb-60">
                            <div className="col-md-6 col-sm-5">
                                <div className="found">
                                    {/*<p>We Found <span>32 Products</span> available for you</p>*/}
                                    <h4>Destaques da loja</h4>
                                </div>
                            </div>
                            {/*  <div className="col-md-6 col-sm-7">
                      <div className="select-option f-right">
                          <select className="selectpicker">
                              <option>Sort By</option>
                              <option>A to Z</option>
                              <option>Z- A</option>
                              <option>High to Low</option>
                              <option>Low to High</option>
                          </select>
                      </div>
                      <div className="select-option f-right">
                          <select className="selectpicker">
                              <option>Select Category</option>
                              <option>Category two</option>
                              <option>Category three</option>
                              <option>Category four</option>
                          </select>
                      </div>
                  </div>*/}
                        </div>

                        <div className="row">
                            {  this.state.loading && [0, 1, 2, 3,0, 1, 2, 3,0, 1, 2, 3].map((value,index) => (
                                <div className="col-md-3 col-sm-4 mb-30" key={index}>
                                    <div className="product-box">
                                        <div className="product-img">
                                                <span>
                                                    {/*<img src="img/product/wait-content-produto.jpg" alt=""/>*/}
                                                    <ShimmerThumbnail width={260} height={315} rounded/>
                                                </span>

                                            <div className="action-box">
                                                {/*<a ><i className="ion-bag"></i></a>*/}
                                                {/*<a ><i className="ion-ios-search-strong"></i></a>*/}
                                                {/*<a ><i className="ion-heart"></i></a>*/}
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            {/*<h3 className="product-title"><a >Medi Product Title</a></h3>*/}
                                            {/*<div className="product-price">*/}
                                            {/*    <span>$50</span>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))
                            }

                            {
                                this.state.loading===false && this.state.destaquesHomeList.length>0 && this.state.destaquesHomeList.map(produtoEstoque => (
                                <div className="col-md-3 col-sm-4 mb-30" key={produtoEstoque.id}>
                                    <ProdutoEstoqueUiComponent produtoEstoque={produtoEstoque}  />
                                </div>

                                ))
                            }

                        </div>
                    </div>
                </div>

            </Layout>
        )
    }
}

export default IndexPage
